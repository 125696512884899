.header {
    padding-top: 80px;
    margin-bottom: 80px;
    @include r(1600) {
        padding-top: 60px;
        margin-bottom: 60px; }
    @include r(1441) {
        padding-top: 30px;
        margin-bottom: 30px; }
    @include r(800) {
        margin-bottom: 80px; }
    @include r(720) {
        padding-top: 25px;
        margin-bottom: 25px; }
    @include r(600) {
        padding-top: 15px;
        margin-bottom: 15px; }
    &__inner {
        display: flex;
        justify-content: space-between;
        @include r(480) {
            justify-content: center; } }
    &__logo {
        .icon {
            width: 160px;
            height: 65px;
            @include r(1600) {
                width: 116px;
                height: 47px; }
            @include r(720) {
                width: 100px;
                height: 40px; } } }
    &__text {
        @include r(1100) {
            display: none; }
        @include r(800) {
            display: block; }
        @include r(480) {
            display: none; }
        span {
            text-transform: uppercase;
            display: block;
            color: #4d2f48;
            font-size: 18px;
            @include sb;
            line-height: 20px;
            @include r(720) {
                font-size: 16px; } } } }
