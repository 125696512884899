.container {
	width: 1740px;
	margin: 0 auto;
	@include r(1800) {
		width: 100%;
		padding: 0px 60px; }
	@include r(900) {
		padding: 0px 25px; } }
.out {
	overflow: hidden; }
