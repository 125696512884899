.icon-arrow-next {
  width: 0.67em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-prev {
  width: 0.67em;
  height: 1em;
  fill: #4d2f48;
}
.icon-badge {
  width: 0.95em;
  height: 1em;
  fill: #fefefe;
}
.icon-bunge {
  width: 2.46em;
  height: 1em;
  fill: #4D2F48;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}
