.slider {
    margin-bottom: 85px;
    margin-left: -113px;
    //margin-right: -25px
    @include r(1500) {
        margin-left: -105px; }
    @include r(1300) {
        margin-left: -5px; }
    @include r(720) {
        margin: 0px 25px 200px 25px; }
    .slick-slide {
        width: 85vw;
        margin: 0 50px;
        @include r(1300) {
            width: 100vw;
            margin: 0px; } } }
.slide {
    &__wrap {
        display: flex;
        height: 580px;
        //max-width: 1624px
        position: relative;
        @include r(1800) {
            max-height: 440px; }
        @include r(600) {
            height: 300px; } }
    &__left {
        background-color: #4d2f48;
        flex: 1;
        padding: 80px 0px 80px 100px;
        display: flex;
        @include r(1300) {
            padding-left: 30px; }
        @include r(1100) {
            padding-right: 350px;
            justify-content: space-between; }
        @include r(800) {
            padding-right: 280px; }
        @include r(600) {
            padding: 32px 25px; } }
    &__right {
        flex: 1;
        @include r(1100) {
            display: none; } }
    &-text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        max-width: 300px;
        @include r(420) {
            max-width: 105px; }
        &_f5 {
            .slide-text__title,.slide-text__sub-title,.slide-text__length-fry,.slide-text__bottle-size {
                color: #ff671b; } }
        &_u1 {
            .slide-text__title,.slide-text__sub-title,.slide-text__length-fry,.slide-text__bottle-size {
                color: #f7d93e; } }
        &__title {
            color: #f6b200;
            font-size:  112px;
            text-transform: uppercase;
            @include xb;
            line-height: 1em;
            @include r(1800) {
                font-size: 72px; }
            @include r(600) {
                font-size: 48px; } }
        &__sub-title {
            font-size: 44px;
            text-transform: uppercase;
            color: #f6b200;
            @include xb;
            margin-top: -17px;
            margin-bottom: 10px;
            display: block;
            @include r(1800) {
                font-size: 32px;
                margin-top: -5px; }
            @include r(600) {
                font-size: 20px; } }
        &__description {
            font-size: 24px;
            color: #fff;
            @include sb;
            @include r(1800) {
                font-size: 20px; }
            @include r(600) {
                font-size: 15px; } }
        &__bottom {
            span {
                color: #f6b200;
                @include sb; } }
        &__length-fry {
            font-size: 48px;
            display: block;
            @include r(1800) {
                font-size: 35px; }
            @include r(600) {
                font-size: 24px;
                display: none; }
            &_mob {
                display: none;
                @include r(600) {
                    display: block; } } }
        &__bottle-size {
            text-transform: uppercase;
            font-size: 24px;
            display: block;
            @include r(1800) {
                font-size: 18px; }
            @include r(600) {
                font-size: 13px; } } }
    &__img {
        width: 100%;
        height: 100%;
        @include r(1400) {
 } }            //width: auto
    &-badges {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: percentage(380px/1740px);
        @include r(1400) {
           margin-left: percentage(280px/1740px); }
        @include r(720) {
            position: absolute;
            flex-direction: row;
            bottom: -140px;
            margin: 0px;
            left: 50%;
            margin-left: -160px; }
        img {
            margin-top: 13px;
            width: 64px;
            height: 64px;
            @include r(720) {
                margin: 0px 10px;
                width: 60px; } }
        &_f5 {
            @include r(720) {
                margin-left: -175px; }
            img {
                @include r(720) {
                    margin: 0px 5px; } } } }
    &-bottle {
        position: absolute;
        top: -142px;
        left: 50%;
        margin-left: -153px;
        @include r(1800) {
            margin-left: -120px; }
        @include r(1441) {
            top: -90px; }
        @include r(1100) {
            left: initial;
            right: 30px; }
        @include r(800) {
            top: 10px; }
        @include r(720) {
            top: 60px; }
        @include r(600) {
            top: 25px; }
        @include r(480) {
            right: 10px; }
        img {
            width: 425px;
            max-height: 779px;
            @include r(1800) {
                width: 328px; }
            @include r(1441) {
                width: 300px; }
            @include r(800) {
                width: 250px; }
            @include r(720) {
                width: 180px; }
            @include r(600) {
                max-height: 330px; } }
        &__badge {
            position: absolute;
            top: 28%;
            right: -10px;
            @include r(720) {
                display: none; }
            .icon {
                width: 106px;
                height: 112px; } } } }
.slider-panel {
    margin-bottom: 80px;
    @include r(720) {
        margin-bottom: 40px; }
    &__email {
        @include r(720) {
            text-align: center; }
        span {
            display: block;
            font-size: 17px;
            line-height: 24px;
            color: #4d2f48;
            @include xb;
            @include r(1024) {
                font-size: 15px;
                @include sb; } }
        a {
           color: #4d2f48;
           text-decoration: none;
           border-bottom: 1px solid #4d2f48; } }
    &__wrap {
        display: flex;
        justify-content: space-between;
        @include r(720) {
            justify-content: center; } }
    &__btns {
        display: flex;
        z-index: 2;
        margin-right: 10px;
        @include r(1500) {
            margin-right: 0px; }
        @include r(720) {
            display: none; }
        .icon {
            width: 8px;
            height: 12px; } } }
.slider-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4d2f48;
    .icon {
        fill: #fff; }
    &.slick-disabled {
        background-color: #efefef;
        .icon {
            fill: #4d2f48; } }
    &__prev {
        .icon {
            transform: rotate(180deg); } }
    &__next {
        margin-left: 17px; } }

.slider {
    .slick-list {
        overflow: initial; }
    .slick-dots {
        bottom: -135px;
        right: -60px;
        @include r(720) {
            bottom: -180px;
            right: 0px; }
        li {
            margin: 0px 50px;
            @include r(1500) {
                margin: 0px 40px; }
            @include r(720) {
                margin: 0px 20px; }
            @include r(480) {
                margin: 0px 10px; } } }
    .slick-dots li button:before {
        background: none;
        border: 2px solid #4d2f48; }
    .slick-dots li button:before {
        @include r(720) {
            width: 8px;
            height: 8px;
            background-color: #dadada;
            border: none; } }
    .slick-dots li button:hover:before, .slick-dots li.slick-active button:before {
        @include r(720) {
            width: 12px;
            height: 12px; } }
    .slick-dots li button:hover:before, .slick-dots li.slick-active button:before {
        background-color: #4d2f48; } }
