.text-block {
    background-color: #f4f4f4;
    position: relative;
    top: 18px;
    @include r(720) {
 }        //padding: 30px 0px
    &_u1 {
        padding: 0px; }
    &__inner {
        display: flex;
        padding: 65px 0px;
        @include r(1100) {
            display: block; }
        &_u1 {
            display: none;
            @include r(1100) {
                display: none; } } }
    &__column {
        flex: 1;
        &_left {
            margin-right: 60px;
            @include r(1100) {
                margin-right: 0px; } }
        p {
            font-size: 11px;
            line-height: 20px;
            color: #4a4a4a;
            @include sl;
            margin-bottom: 15px; } } }
