@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

.icon-arrow-next { width: 0.67em; height: 1em; fill: #fff; }

.icon-arrow-prev { width: 0.67em; height: 1em; fill: #4d2f48; }

.icon-badge { width: 0.95em; height: 1em; fill: #fefefe; }

.icon-bunge { width: 2.46em; height: 1em; fill: #4D2F48; }

.icon-facebook { width: 0.53em; height: 1em; fill: #000; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Slider */
.slick-loading .slick-list { background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face { font-family: "slick"; src: url("../fonts/slick.eot"); src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }

/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -10px; padding: 0; border: none; outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir="rtl"] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir="rtl"] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-dots { position: absolute; bottom: 22px; list-style: none; display: block; text-align: center; padding: 0; width: 100%; /*  @include mobile { bottom: 11px; } */ }

.slick-dots li { position: relative; display: inline-block; cursor: pointer; }

.slick-dots li button { display: block; padding: 3px; background: none; outline: none; line-height: 0px; font-size: 0px; color: red; cursor: pointer; }

.slick-dots li button:before { content: ""; display: block; height: 10px; width: 10px; box-sizing: border-box; border-radius: 50%; background: #979795; transition: all 0.1s ease; }

.slick-dots li button:hover:before { background: #4E4E4E; }

.slick-dots li.slick-active button:before { background: #4E4E4E; }

@font-face { font-family: GrundfosTheSansOT-4SL; src: url("../fonts/GrundfosTheSansOT-4SL.woff2") format("woff2"), url("../fonts/GrundfosTheSansOT-4SL.woff") format("woff"); font-weight: normal; font-style: normal; }

@font-face { font-family: GrundfosTheSansOT-6SB; src: url("../fonts/GrundfosTheSansOT-6SB.woff2") format("woff2"), url("../fonts/GrundfosTheSansOT-6SB.woff") format("woff"); font-weight: normal; font-style: normal; }

@font-face { font-family: GrundfosTheSansOT-8XB; src: url("../fonts/GrundfosTheSansOT-8XB.woff2") format("woff2"), url("../fonts/GrundfosTheSansOT-8XB.woff") format("woff"); font-weight: normal; font-style: normal; }

.container { width: 1740px; margin: 0 auto; }

@media only screen and (max-width: 1800px) { .container { width: 100%; padding: 0px 60px; } }

@media only screen and (max-width: 900px) { .container { padding: 0px 25px; } }

.out { overflow: hidden; }

.block { display: flex; }

.header { padding-top: 80px; margin-bottom: 80px; }

@media only screen and (max-width: 1600px) { .header { padding-top: 60px; margin-bottom: 60px; } }

@media only screen and (max-width: 1441px) { .header { padding-top: 30px; margin-bottom: 30px; } }

@media only screen and (max-width: 800px) { .header { margin-bottom: 80px; } }

@media only screen and (max-width: 720px) { .header { padding-top: 25px; margin-bottom: 25px; } }

@media only screen and (max-width: 600px) { .header { padding-top: 15px; margin-bottom: 15px; } }

.header__inner { display: flex; justify-content: space-between; }

@media only screen and (max-width: 480px) { .header__inner { justify-content: center; } }

.header__logo .icon { width: 160px; height: 65px; }

@media only screen and (max-width: 1600px) { .header__logo .icon { width: 116px; height: 47px; } }

@media only screen and (max-width: 720px) { .header__logo .icon { width: 100px; height: 40px; } }

@media only screen and (max-width: 1100px) { .header__text { display: none; } }

@media only screen and (max-width: 800px) { .header__text { display: block; } }

@media only screen and (max-width: 480px) { .header__text { display: none; } }

.header__text span { text-transform: uppercase; display: block; color: #4d2f48; font-size: 18px; font-family: 'GrundfosTheSansOT-6SB'; line-height: 20px; }

@media only screen and (max-width: 720px) { .header__text span { font-size: 16px; } }

.slider { margin-bottom: 85px; margin-left: -113px; }

@media only screen and (max-width: 1500px) { .slider { margin-left: -105px; } }

@media only screen and (max-width: 1300px) { .slider { margin-left: -5px; } }

@media only screen and (max-width: 720px) { .slider { margin: 0px 25px 200px 25px; } }

.slider .slick-slide { width: 85vw; margin: 0 50px; }

@media only screen and (max-width: 1300px) { .slider .slick-slide { width: 100vw; margin: 0px; } }

.slide__wrap { display: flex; height: 580px; position: relative; }

@media only screen and (max-width: 1800px) { .slide__wrap { max-height: 440px; } }

@media only screen and (max-width: 600px) { .slide__wrap { height: 300px; } }

.slide__left { background-color: #4d2f48; flex: 1; padding: 80px 0px 80px 100px; display: flex; }

@media only screen and (max-width: 1300px) { .slide__left { padding-left: 30px; } }

@media only screen and (max-width: 1100px) { .slide__left { padding-right: 350px; justify-content: space-between; } }

@media only screen and (max-width: 800px) { .slide__left { padding-right: 280px; } }

@media only screen and (max-width: 600px) { .slide__left { padding: 32px 25px; } }

.slide__right { flex: 1; }

@media only screen and (max-width: 1100px) { .slide__right { display: none; } }

.slide-text { display: flex; flex-direction: column; height: 100%; justify-content: space-between; max-width: 300px; }

@media only screen and (max-width: 420px) { .slide-text { max-width: 105px; } }

.slide-text_f5 .slide-text__title, .slide-text_f5 .slide-text__sub-title, .slide-text_f5 .slide-text__length-fry, .slide-text_f5 .slide-text__bottle-size { color: #ff671b; }

.slide-text_u1 .slide-text__title, .slide-text_u1 .slide-text__sub-title, .slide-text_u1 .slide-text__length-fry, .slide-text_u1 .slide-text__bottle-size { color: #f7d93e; }

.slide-text__title { color: #f6b200; font-size: 112px; text-transform: uppercase; font-family: 'GrundfosTheSansOT-8XB'; line-height: 1em; }

@media only screen and (max-width: 1800px) { .slide-text__title { font-size: 72px; } }

@media only screen and (max-width: 600px) { .slide-text__title { font-size: 48px; } }

.slide-text__sub-title { font-size: 44px; text-transform: uppercase; color: #f6b200; font-family: 'GrundfosTheSansOT-8XB'; margin-top: -17px; margin-bottom: 10px; display: block; }

@media only screen and (max-width: 1800px) { .slide-text__sub-title { font-size: 32px; margin-top: -5px; } }

@media only screen and (max-width: 600px) { .slide-text__sub-title { font-size: 20px; } }

.slide-text__description { font-size: 24px; color: #fff; font-family: 'GrundfosTheSansOT-6SB'; }

@media only screen and (max-width: 1800px) { .slide-text__description { font-size: 20px; } }

@media only screen and (max-width: 600px) { .slide-text__description { font-size: 15px; } }

.slide-text__bottom span { color: #f6b200; font-family: 'GrundfosTheSansOT-6SB'; }

.slide-text__length-fry { font-size: 48px; display: block; }

@media only screen and (max-width: 1800px) { .slide-text__length-fry { font-size: 35px; } }

@media only screen and (max-width: 600px) { .slide-text__length-fry { font-size: 24px; display: none; } }

.slide-text__length-fry_mob { display: none; }

@media only screen and (max-width: 600px) { .slide-text__length-fry_mob { display: block; } }

.slide-text__bottle-size { text-transform: uppercase; font-size: 24px; display: block; }

@media only screen and (max-width: 1800px) { .slide-text__bottle-size { font-size: 18px; } }

@media only screen and (max-width: 600px) { .slide-text__bottle-size { font-size: 13px; } }

.slide__img { width: 100%; height: 100%; }

.slide-badges { display: flex; flex-direction: column; justify-content: center; margin-left: 21.83908%; }

@media only screen and (max-width: 1400px) { .slide-badges { margin-left: 16.09195%; } }

@media only screen and (max-width: 720px) { .slide-badges { position: absolute; flex-direction: row; bottom: -140px; margin: 0px; left: 50%; margin-left: -160px; } }

.slide-badges img { margin-top: 13px; width: 64px; height: 64px; }

@media only screen and (max-width: 720px) { .slide-badges img { margin: 0px 10px; width: 60px; } }

@media only screen and (max-width: 720px) { .slide-badges_f5 { margin-left: -175px; } }

@media only screen and (max-width: 720px) { .slide-badges_f5 img { margin: 0px 5px; } }

.slide-bottle { position: absolute; top: -142px; left: 50%; margin-left: -153px; }

@media only screen and (max-width: 1800px) { .slide-bottle { margin-left: -120px; } }

@media only screen and (max-width: 1441px) { .slide-bottle { top: -90px; } }

@media only screen and (max-width: 1100px) { .slide-bottle { left: initial; right: 30px; } }

@media only screen and (max-width: 800px) { .slide-bottle { top: 10px; } }

@media only screen and (max-width: 720px) { .slide-bottle { top: 60px; } }

@media only screen and (max-width: 600px) { .slide-bottle { top: 25px; } }

@media only screen and (max-width: 480px) { .slide-bottle { right: 10px; } }

.slide-bottle img { width: 425px; max-height: 779px; }

@media only screen and (max-width: 1800px) { .slide-bottle img { width: 328px; } }

@media only screen and (max-width: 1441px) { .slide-bottle img { width: 300px; } }

@media only screen and (max-width: 800px) { .slide-bottle img { width: 250px; } }

@media only screen and (max-width: 720px) { .slide-bottle img { width: 180px; } }

@media only screen and (max-width: 600px) { .slide-bottle img { max-height: 330px; } }

.slide-bottle__badge { position: absolute; top: 28%; right: -10px; }

@media only screen and (max-width: 720px) { .slide-bottle__badge { display: none; } }

.slide-bottle__badge .icon { width: 106px; height: 112px; }

.slider-panel { margin-bottom: 80px; }

@media only screen and (max-width: 720px) { .slider-panel { margin-bottom: 40px; } }

@media only screen and (max-width: 720px) { .slider-panel__email { text-align: center; } }

.slider-panel__email span { display: block; font-size: 17px; line-height: 24px; color: #4d2f48; font-family: 'GrundfosTheSansOT-8XB'; }

@media only screen and (max-width: 1024px) { .slider-panel__email span { font-size: 15px; font-family: 'GrundfosTheSansOT-6SB'; } }

.slider-panel__email a { color: #4d2f48; text-decoration: none; border-bottom: 1px solid #4d2f48; }

.slider-panel__wrap { display: flex; justify-content: space-between; }

@media only screen and (max-width: 720px) { .slider-panel__wrap { justify-content: center; } }

.slider-panel__btns { display: flex; z-index: 2; margin-right: 10px; }

@media only screen and (max-width: 1500px) { .slider-panel__btns { margin-right: 0px; } }

@media only screen and (max-width: 720px) { .slider-panel__btns { display: none; } }

.slider-panel__btns .icon { width: 8px; height: 12px; }

.slider-btn { width: 48px; height: 48px; border-radius: 50%; display: flex; align-items: center; justify-content: center; background-color: #4d2f48; }

.slider-btn .icon { fill: #fff; }

.slider-btn.slick-disabled { background-color: #efefef; }

.slider-btn.slick-disabled .icon { fill: #4d2f48; }

.slider-btn__prev .icon { transform: rotate(180deg); }

.slider-btn__next { margin-left: 17px; }

.slider .slick-list { overflow: initial; }

.slider .slick-dots { bottom: -135px; right: -60px; }

@media only screen and (max-width: 720px) { .slider .slick-dots { bottom: -180px; right: 0px; } }

.slider .slick-dots li { margin: 0px 50px; }

@media only screen and (max-width: 1500px) { .slider .slick-dots li { margin: 0px 40px; } }

@media only screen and (max-width: 720px) { .slider .slick-dots li { margin: 0px 20px; } }

@media only screen and (max-width: 480px) { .slider .slick-dots li { margin: 0px 10px; } }

.slider .slick-dots li button:before { background: none; border: 2px solid #4d2f48; }

@media only screen and (max-width: 720px) { .slider .slick-dots li button:before { width: 8px; height: 8px; background-color: #dadada; border: none; } }

@media only screen and (max-width: 720px) { .slider .slick-dots li button:hover:before, .slider .slick-dots li.slick-active button:before { width: 12px; height: 12px; } }

.slider .slick-dots li button:hover:before, .slider .slick-dots li.slick-active button:before { background-color: #4d2f48; }

.text-block { background-color: #f4f4f4; position: relative; top: 18px; }

.text-block_u1 { padding: 0px; }

.text-block__inner { display: flex; padding: 65px 0px; }

@media only screen and (max-width: 1100px) { .text-block__inner { display: block; } }

.text-block__inner_u1 { display: none; }

@media only screen and (max-width: 1100px) { .text-block__inner_u1 { display: none; } }

.text-block__column { flex: 1; }

.text-block__column_left { margin-right: 60px; }

@media only screen and (max-width: 1100px) { .text-block__column_left { margin-right: 0px; } }

.text-block__column p { font-size: 11px; line-height: 20px; color: #4a4a4a; font-family: 'GrundfosTheSansOT-4SL'; margin-bottom: 15px; }
